import { mapGetters } from "vuex";
import { URL } from "@/api/report.js";
import InputMoney from "@/components/inputMoney.vue";

export default {
  name: "tongHopThueGtgt",
  components: {
    InputMoney,
  },
  data() {
    return {
      dataTableSummary: [],
      totalTableSummary: {},
      companyName: null,
    };
  },
  created() {
    this.activeTabs = this.routeName;
    this.getDetailReport();
  },

  methods: {
    async getDetailReport() {
      this.$store.dispatch("setIsReportLoading", true)
      const params = {};
      params.company_id = this.selectCurrentCompany;
      params.report_type = this.routeName;
      params.report_year = this.getReportYear;

      if (this.selectCurrentCompany && this.getReportYear) {
        const result = await this.$request({
          url: URL.REPORT,
          params,
        });
        const res = result.data;

        if (res.code == 200) {
          this.dataTableSummary = res.data.summary;
          this.totalTableSummary = res.data.sum_amount;
          this.$store.dispatch("setIsDownloadable", res.data.is_downloadable);
          this.$store.dispatch("setIsDownloadProcessing", res.data.is_download_processing);
          this.$store.dispatch("setIsLargePDF", res.data.is_large_pdf);
        }
      }

      this.$store.dispatch("setIsReportLoading", false)
    },
  },

  computed: {
    ...mapGetters([
      "selectCurrentCompany",
      "getReportYear",
      "getTargetCompany",
    ]),
    routeName() {
      return this.$route.name;
    },
  },

  watch: {
    selectCurrentCompany() {
      this.getDetailReport();
    },
    getReportYear() {
      this.getDetailReport();
    },
  },
};
