<div class="mx-auto border-2 bg-white p-4">
  <div class="header">
    <h5>Tên người nộp thuế: {{ getTargetCompany.tenDoanhNghiep }}</h5>
    <h5>Mã số thuế: {{ getTargetCompany.mst }}</h5>
    <div class="titleTable text-center mt-2 border-2 border-black py-2">
      <p class="font-bold">BẢNG TỔNG HỢP THUẾ GIÁ TRỊ GIA TĂNG</p>
    </div>
    <p class="text-center font-normal mt-1 text-sm">Năm {{ getReportYear }}</p>
  </div>

  <div class="dataTable mt-4 hscroll outer-wrapper min-width-table w-full">
    <table class="table-auto border text-center w-full text-sm">
      <thead>
        <tr class="bg-yellow-400">
          <th rowspan="2" class="fixed-width">Kỳ kê khai</th>
          <th rowspan="2">VAT khấu trừ đầu kỳ</th>
          <th rowspan="2">Giá trị hàng mua vào chưa VAT</th>
          <th rowspan="2">VAT mua vào trong kỳ</th>
          <th colspan="5" class="bg-blue-400">Doanh thu trong năm</th>
          <th colspan="3">VAT đầu ra</th>
          <th rowspan="2">VAT đề nghị hoàn</th>
          <th rowspan="2">VAT cuối kỳ</th>
          <th rowspan="2" class="w-f-10 fixed-width">Kết xuất báo cáo</th>
        </tr>

        <tr>
          <th class="bg-blue-200">KCT</th>
          <th class="bg-blue-200">0%</th>
          <th class="bg-blue-200">5%</th>
          <th class="bg-blue-200">10%</th>
          <th class="bg-blue-200">Cộng</th>
          <th class="bg-yellow-200">VAT đầu ra</th>
          <th class="bg-yellow-200">Điều chỉnh trong kỳ</th>
          <th class="bg-yellow-200">VAT đầu ra sau điều chỉnh</th>
        </tr>

        <tr>
          <td></td>
          <td>[1]</td>
          <td>[2]</td>
          <td>[3]</td>
          <td>[4]</td>
          <td>[5]</td>
          <td>[6]</td>
          <td>[7]</td>
          <td>[8]=[4]+[5]+[6]+[7]</td>
          <td>[9]</td>
          <td>[10]</td>
          <td>[11]</td>
          <td>[12]</td>
          <td>[13]=[1]+[3]-[11]-[12]</td>
          <td></td>
        </tr>
      </thead>

      <tbody>
        <tr v-for="(item, index) in dataTableSummary" :key="index">
          <td>{{item.period}}</td>
          <td>
            <input-money
              v-model.lazy="item.vat_start_period"
              class="money"
              :invoiceStyle="true"
              :table="true"
              :decima="0"
            />
          </td>
          <td>
            <input-money
              v-model.lazy="item.amount_input"
              class="money"
              :invoiceStyle="true"
              :table="true"
              :decima="0"
            />
          </td>
          <td>
            <input-money
              v-model.lazy="item.vat_input"
              class="money"
              :invoiceStyle="true"
              :table="true"
              :decima="0"
            />
          </td>
          <td>
            <input-money
              v-model.lazy="item.revenue_no_tax"
              class="money"
              :invoiceStyle="true"
              :table="true"
              :decima="0"
            />
          </td>
          <td>
            <input-money
              v-model.lazy="item.revenue_0"
              class="money"
              :invoiceStyle="true"
              :table="true"
              :decima="0"
            />
          </td>
          <td>
            <input-money
              v-model.lazy="item.revenue_5"
              class="money"
              :invoiceStyle="true"
              :table="true"
              :decima="0"
            />
          </td>
          <td>
            <input-money
              v-model.lazy="item.revenue_10"
              class="money"
              :invoiceStyle="true"
              :table="true"
              :decima="0"
            />
          </td>
          <td>
            <input-money
              v-model.lazy="item.total_revenue"
              class="money"
              :invoiceStyle="true"
              :table="true"
              :decima="0"
            />
          </td>
          <td>
            <input-money
              v-model.lazy="item.vat_output"
              class="money"
              :invoiceStyle="true"
              :table="true"
              :decima="0"
            />
          </td>
          <td>
            <input-money
              v-model.lazy="item.vat_output_adjust"
              class="money"
              :invoiceStyle="true"
              :table="true"
              :decima="0"
            />
          </td>
          <td>
            <input-money
              v-model.lazy="item.vat_output_after_adjust"
              class="money"
              :invoiceStyle="true"
              :table="true"
              :decima="0"
            />
          </td>
          <td>
            <input-money
              v-model.lazy="item.tax_refund_proposal"
              class="money"
              :invoiceStyle="true"
              :table="true"
              :decima="0"
            />
          </td>
          <td>
            <input-money
              v-model.lazy="item.vat_end_period"
              class="money"
              :invoiceStyle="true"
              :table="true"
              :decima="0"
            />
          </td>
          <td>{{item.report_status}}</td>
        </tr>

        <tr class="bg-total">
          <td colspan="2">Cộng</td>
          <td>
            <input-money
              v-model="totalTableSummary.amount_input"
              class="money"
              :invoiceStyle="true"
              :table="true"
              :decima="0"
            />
          </td>
          <td>
            <input-money
              v-model="totalTableSummary.vat_input"
              class="money"
              :invoiceStyle="true"
              :table="true"
              :decima="0"
            />
          </td>
          <td>
            <input-money
              v-model="totalTableSummary.revenue_no_tax"
              class="money"
              :invoiceStyle="true"
              :table="true"
              :decima="0"
            />
          </td>
          <td>
            <input-money
              v-model="totalTableSummary.revenue_0"
              class="money"
              :invoiceStyle="true"
              :table="true"
              :decima="0"
            />
          </td>
          <td>
            <input-money
              v-model="totalTableSummary.revenue_5"
              class="money"
              :invoiceStyle="true"
              :table="true"
              :decima="0"
            />
          </td>
          <td>
            <input-money
              v-model="totalTableSummary.revenue_10"
              class="money"
              :invoiceStyle="true"
              :table="true"
              :decima="0"
            />
          </td>
          <td>
            <input-money
              v-model="totalTableSummary.total_revenue"
              class="money"
              :invoiceStyle="true"
              :table="true"
              :decima="0"
            />
          </td>
          <td>
            <input-money
              v-model="totalTableSummary.vat_output"
              class="money"
              :invoiceStyle="true"
              :table="true"
              :decima="0"
            />
          </td>
          <td>
            <input-money
              v-model="totalTableSummary.vat_output_adjust"
              class="money"
              :invoiceStyle="true"
              :table="true"
              :decima="0"
            />
          </td>
          <td>
            <input-money
              v-model="totalTableSummary.vat_output_after_adjust"
              class="money"
              :invoiceStyle="true"
              :table="true"
              :decima="0"
            />
          </td>
          <td>
            <input-money
              v-model="totalTableSummary.tax_refund_proposal"
              class="money"
              :invoiceStyle="true"
              :table="true"
              :decima="0"
            />
          </td>
          <td></td>
          <td></td>
        </tr>
      </tbody>
    </table>
  </div>
</div>
